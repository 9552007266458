.logo {
    fill:white;
    width: 100%;
    height: 100px;
}
.body {
    background-color: rgb(17, 31, 16);
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.login_container {
    width: 45vh;
    height: 40vh;
    max-width: 500px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    padding: 5vmin;
}
.avatar{
    width: 35vw;
    max-height: 30vmin;
    max-width: 30vmin;
    border-radius: 50%;
    border: 0.7vmin solid rgba(255, 255, 255, 0.3);
}
.avatar_small{
    width: 50px;
    max-height: 50px;
    max-width: 50px;
    border-radius: 50%;
    border: 0.7vmin solid rgba(255, 255, 255, 0.3);
}